<template>
  <form-observer ref="observer" @submit.prevent="onSubmit">
    <div class="columns">
      <div class="column">
        <input-base
          rules=""
          label="Descripción breve" 
          type="textarea"
          placeholder="Descripción breve"
          v-model="form.descripcion"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <tiny-editor
          rules=""
          label="Descripción larga" 
          placeholder="Descripción larga"
          v-model="form.descripcion_larga"
        />
      </div>
    </div>
    <div>
      <b-button
        native-type="submit"
        type="is-primary"
        icon-left="save"
        :loading="loading"
        class="mt-3 mr-3"
        >Guardar</b-button>
    </div>
  </form-observer>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  data(){
    return {
      form: {},
      loading: false,
    }
  },
  computed:{
    ...mapGetters({
      empresa: "entitie/empresa",
    }),
  },
  created() {
    const {descripcion,descripcion_larga} = this.empresa ?? {}
    this.$set(this, 'form', {
      descripcion: descripcion ?? ``,
      descripcion_larga: descripcion_larga ?? ``,
    })
  },
  methods: {
    async onSubmit(){
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      this.loading = true
      const data = await this.$store.dispatch('entitie/saveRequest', this.form)
      if(data){
        this.$store.dispatch(
          "app/setToastSuccess",
          `Acabamos de guardar las descripciones de la empresa.`
        );
      }
      this.loading = false
    }
  }
};
</script>

