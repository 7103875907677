<template>
  <div>
    <title-bar :items="[{ title }]" />

    <div class="box">
      <b-tabs type="is-boxed">
        <b-tab-item label="General">
          <form-general ></form-general>
        </b-tab-item>
        <b-tab-item label="Descripciones">
          <form-otros ></form-otros>
        </b-tab-item>
        <b-tab-item label="App" v-if="isRoot">
          <form-app ></form-app>
        </b-tab-item>
        <b-tab-item label="Plan de contrato">
          <form-plan ></form-plan>
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import FormApp from '../components/FormApp.vue';
import FormOtros from '../components/FormOtros.vue';
import FormGeneral from '../components/FormGeneral.vue';
import FormPlan from '../components/FormPlan.vue';
import {mapGetters} from 'vuex'
// 
export default {
  components: { FormGeneral, FormOtros, FormApp, FormPlan },
  computed:{
    ...mapGetters({
      isRoot: 'auth/isRoot',
    }),
    title(){
      return this.$route.meta.title || "";
    }
  },
};
</script>
