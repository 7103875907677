<template>
  <form-observer ref="observer" @submit.prevent="onSubmit">
    <div class="columns">
      <div class="column is-4">
        <date-picker-base
          rules="required"
          label="Fecha de vencimiento"
          v-model="form.fecha_vencimiento"
          :disabled="!isRoot"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column is-4">
        <input-base
          rules="required|numeric"
          label="Usuarios máx."
          maxlength="3"
          placeholder="1"
          type="number"
          v-model="form.max_usuarios"
          :disabled="!isRoot"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column is-4">
        <input-base
          rules="required|numeric"
          label="Informes máx. por usuario."
          maxlength="3"
          placeholder="1"
          type="number"
          v-model="form.max_informes"
          :disabled="!isRoot"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column is-4">
        <input-base
          rules="required|numeric"
          label="Páginas máx. por informe"
          maxlength="3"
          placeholder="1"
          type="number"
          v-model="form.max_pages"
          :disabled="!isRoot"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column is-4">
        <input-base
          rules="required|numeric"
          label="Gráficos máx. por página"
          maxlength="3"
          placeholder="1"
          type="number"
          v-model="form.max_graficos"
          :disabled="!isRoot"
        />
      </div>
    </div>
    <div v-if="isRoot">
      <b-button
        native-type="submit"
        type="is-primary"
        icon-left="save"
        :loading="loading"
        class="mt-3 mr-3"
        >Guardar</b-button>
    </div>
  </form-observer>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatDate, parseDate } from '@/utils/helpers';
export default {
  data(){
    return {
      form: {},
      loading: false,
    }
  },
  computed:{
    ...mapGetters({
      config: "entitie/config",
      isRoot: "auth/isRoot",
    }),
  },
  created() {
    const {plan_contrato = {}} = this.config ?? {}
    const attrs = plan_contrato.attrs ?? {}
    this.$set(this, 'form', {
      fecha_vencimiento: parseDate(attrs.fecha_vencimiento) ?? new Date(),
      max_usuarios: attrs.max_usuarios ?? `1`,
      max_informes: attrs.max_informes ?? `1`,
      max_pages: attrs.max_pages ?? `1`,
      max_graficos: attrs.max_graficos ?? `1`,
    })
  },
  methods: {
    async onSubmit(){
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      this.loading = true
      const data = await this.$store.dispatch('entitie/saveConfigRequest',{
        key: 'plan_contrato',
        attrs: {
          ...this.form,
          fecha_vencimiento: formatDate(this.form.fecha_vencimiento)
        },
      })
      if(data){
        this.$store.dispatch(
          "app/setToastSuccess",
          `Acabamos de guardar los datos del Plan de contrato.`
        );
      }
      this.loading = false
    }
  }
};
</script>
