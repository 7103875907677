<template>
  <form-observer ref="observer" @submit.prevent="onSubmit">
    <div class="columns">
      <div class="column">
        <input-base
          rules="required"
          label="Name"
          maxlength="100"
          placeholder="Name"
          v-model="form.app_name"
        />
      </div>
      <div class="column">
        <input-base
          rules="required"
          label="Short Name"
          maxlength="50"
          placeholder="Short Name"
          v-model="form.app_shortname"
        />
      </div>
      <div class="column">
        <input-base
          rules=""
          label="Description"
          maxlength="250"
          placeholder="Description"
          v-model="form.app_description"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <select-base
          rules="required"
          label="Display"
          :options="options"
          v-model="form.app_display"
          expanded
        />
      </div>
      <div class="column">
        <input-base
          rules="required"
          label="App Scope"
          maxlength="100"
          placeholder="/"
          v-model="form.app_scope"
        />
      </div>
      <div class="column">
        <input-base
          rules="required"
          label="Start URL"
          maxlength="100"
          placeholder="/"
          v-model="form.app_starturl"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <input-color-base
          rules="required"
          label="Theme Color"
          placeholder="#124578"
          v-model="form.app_themecolor"
        />
      </div>
      <div class="column">
        <input-color-base
          rules="required"
          label="Background Color"
          placeholder="#124578"
          v-model="form.app_bgcolor"
        />
      </div>
      <div class="column">
        <input-image-base
          rules=""
          label="Icono"
          v-model="form.icon_file"
          :src="icon_url"
          @remove="() => form.icon_remove = true"
        />
      </div>
    </div>
    <div>
      <b-button
        native-type="submit"
        type="is-primary"
        icon-left="save"
        :loading="loading"
        class="mt-3 mr-3"
        >Guardar</b-button>
    </div>
  </form-observer>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data(){
    return {
      form: {},
      loading: false,
    }
  },
  computed:{
    ...mapGetters({
      config: "entitie/config",
      icon_url: "entitie/icon_url",
    }),
    options(){
      return [
        {id:"browser", value: "Browser"},
        {id:"standalone", value: "Standalone"},
        {id:"minimal-ui", value: "Minimal UI"},
        {id:"fullscreen", value: "Fullscreen"},
      ]
    },
  },
  created() {
    const {pwa = {}} = this.config ?? {}
    const attrs = pwa.attrs ?? {}
    this.$set(this, 'form', {
      app_name: attrs.app_name ?? ``,
      app_shortname: attrs.app_shortname ?? ``,
      app_description: attrs.app_description ?? ``,
      app_display: attrs.app_display ?? `fullscreen`,
      app_scope: attrs.app_scope ?? `/`,
      app_starturl: attrs.app_starturl ?? `/`,
      app_themecolor: attrs.app_themecolor ?? `#204186`,
      app_bgcolor: attrs.app_bgcolor ?? `#204186`,
    })
  },
  methods: {
    async onSubmit(){
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      this.loading = true
      const data = await this.$store.dispatch('entitie/saveRequest', this.form)
      if(data){
        this.$store.dispatch(
          "app/setToastSuccess",
          `Acabamos de guardar los datos de la App.`
        );
      }
      this.loading = false
    }
  }
};
</script>
