<template>
  <form-observer ref="observer" @submit.prevent="onSubmit">
    <div class="columns">
      <div class="column">
        <input-base
          rules="required|numeric|digits:11"
          label="RUC"
          maxlength="11"
          placeholder="RUC"
          v-model="form.ruc"
        />
      </div>
      <div class="column">
        <input-base
          rules="required"
          label="Nombre de negocio"
          maxlength="200"
          placeholder="Nombre de negocio"
          v-model="form.nombre_negocio"
        />
      </div>
      <div class="column">
        <input-base
          rules="required"
          label="Nombre comercial"
          maxlength="200"
          placeholder="Nombre comercial"
          v-model="form.nombre_comercial"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <input-base
          rules=""
          label="Dirección"
          maxlength="150"
          placeholder="Dirección"
          v-model="form.direccion"
        />
      </div>
      <div class="column">
        <select-search-uri
          rules=""
          label="Región / Provincia / Distrito" 
          uri="/ubigeo/select"
          v-model="form.ubigeo_id"
          expanded
        />
      </div>
      <div class="column">
        <input-base
          rules="email"
          label="E-mail"
          maxlength="100"
          placeholder="E-mail"
          v-model="form.email"
        />
      </div>
      
    </div>
    <div class="columns">
      <div class="column">
        <input-base
          rules="numeric"
          label="Celular"
          maxlength="11"
          placeholder="Celular"
          v-model="form.celular"
        />
      </div>
      <div class="column">
        <input-base
          rules="numeric"
          label="Teléfono"
          maxlength="11"
          placeholder="Teléfono"
          v-model="form.telefono"
        />
      </div>
      <div class="column">
        <input-base
          rules=""
          label="Sitio web"
          maxlength="150"
          placeholder="Sitio web"
          v-model="form.sitio_web"
        />
      </div>
    </div>
    <div class="columns">      
      <div class="column is-2">
        <input-image-base
          rules=""
          label="Logo"
          v-model="form.logo_file"
          :src="logo_url"
          @remove="() => form.logo_remove = true"
        />
      </div>
    </div>
    <div>
      <b-button
        native-type="submit"
        type="is-primary"
        icon-left="save"
        :loading="loading"
        class="mt-3 mr-3"
        >Guardar</b-button>
    </div>
  </form-observer>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data(){
    return {
      form: {},
      loading: false,
    }
  },
  computed:{
    ...mapGetters({
      empresa: "entitie/empresa",
      logo_url: "entitie/logo_url",
    }),
  },
  created() {
    const e = this.empresa ?? {}
    this.$set(this, 'form', {
      ruc: e.ruc ?? ``,
      nombre_negocio: e.nombre_negocio ?? ``,
      nombre_comercial: e.nombre_comercial ?? ``,
      direccion: e.direccion ?? ``,
      ubigeo_id: e.ubigeo_id ?? ``,
      email: e.email ?? ``,
      celular: e.celular ?? ``,
      telefono: e.telefono ?? ``,
      sitio_web: e.sitio_web ?? ``,
    })
  },
  methods: {
    async onSubmit(){
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      this.loading = true
      const data = await this.$store.dispatch('entitie/saveRequest', this.form)
      if(data){
        this.$store.dispatch(
          "app/setToastSuccess",
          `Acabamos de guardar los datos generales de la empresa.`
        );
      }
      this.loading = false
    }
  }
};
</script>
